import styled from 'styled-components';

// NOTE: The object of this is to serve as a "ruler" so we can measure the height of the device including the address bar height.
// More details at: https://lamimed.atlassian.net/browse/TEC-994
export const HeightControlContainer = styled.div.attrs({
  id: 'control-height',
})`
  position: absolute;
  height: 100vh;
  width: 0;
  pointer-events: none;
`;
