import { colors } from '@lamimed/pantheon';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import {
  Close,
  Dialog,
  DoctorDesktop,
  DoctorMobile,
  Heartbeat,
  MedicalReport,
  Menu,
  Stethoscope,
  SymptomCheckerDesktop,
  SymptomCheckerMobile,
  TelemedicinaDesktop,
  TelemedicinaMobile,
  TestCovid,
  TestTube,
  Ultrasound,
} from './svg-as-fc';

interface IconProps {
  color?: keyof typeof colors;
  height?: number;
  width?: number;
}

export const MenuIcon = styled(Menu)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const SymptomCheckerDesktopIcon = styled(
  SymptomCheckerDesktop,
)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const SymptomCheckerMobileIcon = styled(SymptomCheckerMobile)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const CloseIcon = styled(Close)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const DialogIcon = styled(Dialog)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const DoctorMobileIcon = styled(DoctorMobile)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const DoctorDesktopIcon = styled(DoctorDesktop)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const HeartbeatIcon = styled(Heartbeat)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const MedicalReportIcon = styled(MedicalReport)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const StethoscopeIcon = styled(Stethoscope)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const TelemedicinaDesktopIcon = styled(TelemedicinaDesktop)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const TelemedicinaMobileIcon = styled(TelemedicinaMobile)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const TestCovidIcon = styled(TestCovid)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const TestTubeIcon = styled(TestTube)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;

export const UltrasoundIcon = styled(Ultrasound)<IconProps>`
  ${({ color = 'dark', height = 18, width = 18 }) => css`
    color: ${colors[color]};
    height: ${rem(height, 10)};
    width: ${rem(width, 10)};
  `}
`;
