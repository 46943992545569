/* eslint-disable react/jsx-newline */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@lamimed/pantheon';
import Cookies from 'js-cookie';
import Link from 'next/link';

import { useCookie } from 'hooks/useCookie';
import { useIsWidget } from 'hooks/useIsWidget';

import * as S from './styles';

const cookieKey = 'cookies_acceptance';

export const CookiesWarning: React.FC = () => {
  const { t } = useTranslation();
  const [accepted, setAccepted] = useState(true);
  const { set } = useCookie();
  const { isWidget, isWidgetFirstRender } = useIsWidget();

  const handleAcceptance = () => {
    setAccepted(true);

    set(cookieKey, 'true');
  };

  useEffect(() => {
    if (isWidget) {
      setAccepted(!!isWidget);

      set(cookieKey, 'true');

      return;
    }

    setAccepted(!!Cookies.get(cookieKey));
  }, [isWidget, set]);

  if (accepted || isWidgetFirstRender === '1' || isWidget) return <></>;

  return (
    <S.Wrapper isOpen={!accepted}>
      <div>
        <Typography>{t('global.cookieWarningTitle')}</Typography>

        <S.Disclaimer>
          {t('global.cookieWarningDescription')}
          <Link href="/cookies-policy">cookie policy</Link>.
        </S.Disclaimer>
      </div>

      <S.AcceptButton variant="clear" size="small" onClick={handleAcceptance}>
        {t('global.cookieWarningCta')}
      </S.AcceptButton>
    </S.Wrapper>
  );
};
