import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';

import { useCookie } from 'hooks/useCookie';

const lamiCookieHash = 'lamiId';

export const useLamiId = () => {
  const router = useRouter();
  const { get, set } = useCookie();

  const getLamiId = () => {
    if (router.isReady && router.query?.lamiId) {
      const userId = router.query?.lamiId as string;

      set(lamiCookieHash, userId);

      return userId;
    }

    const lamiCookieValue = get(lamiCookieHash);

    if (lamiCookieValue) {
      return lamiCookieValue;
    }

    const userHash = nanoid();

    set(lamiCookieHash, userHash);

    return userHash;
  };

  return { getLamiId };
};
