import { AxiosError } from 'axios';

export interface ErrorMessage {
  message: string;
  error: string;
}

export const getAxiosError = (error: unknown) => {
  /* NOTE: this workaround is only required until the next axios release and we will be able
  to pass the error type into isAxiosError
  related issue: https://github.com/axios/axios/pull/4344 */

  return error as AxiosError<ErrorMessage>;
};
