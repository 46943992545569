import { generateFingerprint } from '@lamimed/utils';

import { sendToErrorTracking } from 'utils/sendToErrorTracking';

const FINGERPRINT_STORAGE_KEY = 'fingerprint';

export function getFingerprint() {
  if (typeof window === 'undefined') return '';

  const fingerprint = localStorage.getItem(FINGERPRINT_STORAGE_KEY);

  try {
    if (!fingerprint) generateFingerprint(FINGERPRINT_STORAGE_KEY);
  } catch (error) {
    sendToErrorTracking({
      error,
      where: 'axiosFactory#generateFingerprint',
      context: {},
    });
  }

  return fingerprint ?? 'anonymousUser';
}
