import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@lamimed/pantheon';
import Head from 'next/head';
import Link from 'next/link';

import { useIsWidget } from 'hooks/useIsWidget';

import { ErrorRedirect, errorRedirect } from 'utils/errorRedirect';
import {
  whatsappMessage,
  whatsappNumber,
  whatsappUrl,
} from 'utils/whatsappUrl';

import * as ErrorS from 'templates/ErrorPage';

import { Header } from 'components/Header';

import * as S from './styles';

export const InternalErrorTemplate = () => {
  const { isWidget } = useIsWidget();

  useEffect(() => {
    if (isWidget) errorRedirect(ErrorRedirect.internalError);
  }, [isWidget]);

  return (
    <>
      <Head>
        <title key="title">Internal Server Error - Lami: Health delivery</title>
      </Head>

      <Header />

      <Grid.Wrapper>
        <ErrorS.Hero>
          <ErrorS.HeroImageContainer>
            <ErrorS.CatImage
              aria-hidden
              src="/images/illustration/cat.gif"
              width={95}
              height={93}
            />

            <ErrorS.ErrorImage
              src="/images/illustration/500.png"
              alt="500 - Internal Server Error"
              width={495}
              height={295}
              quality={100}
            />
          </ErrorS.HeroImageContainer>

          <ErrorS.TextWrapper>
            <Typography variant="heading_1" color="text__highlight" as="h1">
              Internal Server Error
            </Typography>

            <Typography as="h2">
              Ops... Abbiamo si è verificato un errore! Puoi contattarci
              cliccando sul bottone supporto.
              <Typography marginTop="large">
                Se hai bisogno di aiuto, contattaci via chat, oppure accedi
                direttamente ad uno dei nostri servizi.
              </Typography>
            </Typography>
          </ErrorS.TextWrapper>
        </ErrorS.Hero>

        {!isWidget && (
          <S.ButtonsWrapper>
            <Link
              href={whatsappUrl(
                whatsappNumber.default,
                whatsappMessage.default,
              )}
              passHref
              legacyBehavior
            >
              <Button variant="outline" size="large" as="a" target="_blank">
                Supporto
              </Button>
            </Link>

            <Link href="/" passHref legacyBehavior>
              <Button size="large" as="a">
                Home page
              </Button>
            </Link>
          </S.ButtonsWrapper>
        )}
      </Grid.Wrapper>
    </>
  );
};
