import { createContext, useContext, useState } from 'react';

import { Coupon as CouponModel, Status } from 'models/Coupon';

export type PartnershipCoupon = Pick<CouponModel, 'status' | 'couponId'>;

interface CouponCtx {
  storeCoupon: (receivedCoupon: PartnershipCoupon) => void;
  removeCoupon: () => void;
  coupon?: PartnershipCoupon;
}

const CouponContext = createContext({} as CouponCtx);

export const CouponProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [coupon, setCoupon] =
    useState<Pick<PartnershipCoupon, 'status' | 'couponId'>>();

  const storeCoupon = (receivedCoupon: PartnershipCoupon) => {
    setCoupon(receivedCoupon);
  };

  const removeCoupon = () => {
    setCoupon({
      status: Status.disable,
      couponId: '',
    });
  };

  return (
    <CouponContext.Provider
      value={{
        storeCoupon,
        removeCoupon,
        coupon,
      }}
    >
      {children}
    </CouponContext.Provider>
  );
};

export const useCoupon = () => {
  const context = useContext(CouponContext);

  if (!context) {
    throw new Error('useCoupon hook must be used under CouponProvider');
  }

  return context;
};
