import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface IsWidgetCtx {
  isWidget: boolean | null;
  isWidgetFirstRender: string | string[] | undefined;
  parentOrigin: string;
}

const IsWidget = createContext({} as IsWidgetCtx);

export const IsWidgetProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isWidget, setIsWidget] = useState<boolean | null>(null);
  const [parentOrigin, setParentOrigin] = useState('');

  const {
    query: { widget, origin, developmentWidget },
    isReady,
  } = useRouter();

  useEffect(() => {
    if (!isReady) return;

    if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
      const queryDevelopmentWidget = developmentWidget as string;
      if (!queryDevelopmentWidget) return;

      if (!queryDevelopmentWidget?.match(/^[01]$/)) {
        console.error('developmentWidget queryParam should be either 1 or 0');

        return;
      }

      sessionStorage.setItem('developmentWidget', queryDevelopmentWidget);

      setIsWidget(developmentWidget === '1');
    }

    if (origin) {
      sessionStorage.setItem('parentOrigin', origin as string);

      setParentOrigin(origin as string);

      return;
    }
  }, [isReady, developmentWidget, origin]);

  useEffect(() => {
    const sessionOrigin = sessionStorage.getItem('parentOrigin');

    sessionOrigin && setParentOrigin(sessionOrigin);

    if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
      const developmentWidget = sessionStorage.getItem(
        'developmentWidget',
      ) as string;

      if (developmentWidget) {
        setIsWidget(developmentWidget === '1');

        return;
      }
    }

    setIsWidget(!(window === window.parent || window.opener));
  }, []);

  return (
    <IsWidget.Provider
      value={{ isWidget, isWidgetFirstRender: widget, parentOrigin }}
    >
      {children}
    </IsWidget.Provider>
  );
};

export const useIsWidget = () => {
  const context = useContext(IsWidget);

  if (!context) {
    throw new Error('useIsWidget hook must be used under IsWidgetProvider');
  }

  return context;
};
