import { colors, layers, media, opacity, spacing } from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

import { headerHeightMobile } from '../../styles';

import { MobileWrapperProps } from '.';

export const Footer = styled.div`
  position: fixed;
  background-color: transparent;
  z-index: ${layers.menu};
  right: ${spacing.medium};
  bottom: ${spacing.medium};

  ${media.greaterThan('tablet')`
    display: none;
  `}
`;

export const MenuBackground = styled.div`
  position: fixed;
  width: 100%;
  top: ${headerHeightMobile};
  left: 0;
  z-index: ${layers.above};

  ${media.greaterThan('tablet')`
    display: none;
  `}
`;

export const MobileMenuWrapper = styled.div<MobileWrapperProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 100%;
  height: 100%;

  ${Footer} {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }

  ${MenuBackground} {
    ${({ isOpen }) =>
      isOpen &&
      css`
        background-color: ${colors.dark};
        opacity: ${opacity.hard};
        height: 100%;
      `}
  }
`;
