import { borderWidths, colors, radius, spacing } from '@lamimed/pantheon';
import Link from 'next/link';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { FooterLinkProps } from '.';

const variantModifier = {
  default: (isIconStroke: boolean) => css`
    background-color: ${rgba(colors.light, 0.05)};

    & svg {
      fill: ${isIconStroke ? 'none' : colors.light};

      stroke: ${isIconStroke ? colors.light : 'none'};

      & path {
        fill: ${isIconStroke ? 'none' : colors.light};
      }
    }
  `,
  outline: (isIconStroke: boolean) => css`
    background-color: transparent;
    border: ${borderWidths.thin} solid ${colors.light};

    & svg {
      fill: ${isIconStroke ? 'none' : colors.light};

      stroke: ${isIconStroke ? colors.light : 'none'};

      & path {
        fill: ${isIconStroke ? 'none' : colors.light};
      }
    }
  `,
  clear: (isIconStroke: boolean) => css`
    background-color: ${colors.light};

    & svg {
      fill: ${isIconStroke ? 'none' : colors.dark};

      stroke: ${isIconStroke ? colors.dark : 'none'};
    }
  `,
};

const fontSizeModifier = {
  small: css`
    & p {
      font-size: 1rem;
    }
  `,
  default: css`
    & p {
      font-size: 1.2rem;
    }
  `,
  medium: css`
    & p {
      font-size: 1.4rem;
    }
  `,
};

export const FooterLinkWrapper = styled(Link)<
  Omit<FooterLinkProps, 'children' | 'link'>
>`
  ${({
    variant = 'default',
    alignment = 'left',
    width = '100%',
    fontSize = 'default',
    isIconStroke = false,
  }) => css`
    width: ${width};
    height: 4rem;
    border-radius: ${radius.small};
    border: none;
    padding: ${spacing.small};
    text-decoration: none;
    display: flex;
    justify-content: ${alignment};
    align-items: center;

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: ${spacing.small};
    }

    ${fontSizeModifier[fontSize]};
    ${variantModifier[variant](isIconStroke)};
  `}
`;
