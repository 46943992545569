import { MenuProvider } from 'hooks/useMenu';

interface DefaultLayoutProps {
  footer?: React.ReactNode;
}

export const DefaultLayout = ({
  footer,
  children,
}: React.PropsWithChildren<DefaultLayoutProps>) => {
  return (
    <MenuProvider>
      {children}

      {footer}
    </MenuProvider>
  );
};
