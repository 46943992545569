import {
  colors,
  FileEarmarkCheckFill,
  gap,
  media,
  NewTypography as PantheonTypography,
  QuestionCircle,
  radius,
  spacing,
  User,
  Users,
} from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

type ContentContainerProps = {
  mobileMarginBottom?: keyof typeof spacing;
  mobileMarginTop?: keyof typeof spacing;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  ${({ mobileMarginBottom = 'small', mobileMarginTop = 'none' }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${spacing[mobileMarginBottom]};
    margin-top: ${spacing[mobileMarginTop]};
    max-height: 3.5rem;
    height: 100%;
    text-decoration: none;

    ${media.greaterThan('tablet')`
      justify-content: center;
      margin-bottom: ${spacing.none};

      ${gap(spacing.small)};
    `};
  `};
`;

export const IconContainer = styled.div`
  ${media.lessThan('tablet')`
    background-color: ${colors.light};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: calc(${spacing.small} / 2);
    height: 3.5rem;
    width: 3.5rem;
    margin-right: ${spacing.small};
  `};
`;

export const FileMarkIcon = styled(FileEarmarkCheckFill).attrs({
  color: 'dark',
})`
  display: flex;
  align-self: center;
  justify-self: center;
  width: 2rem;
  height: 2rem;
  color: ${colors.dark};
`;

export const UsersIcon = styled(Users).attrs({
  color: 'dark',
})`
  display: flex;
  align-self: center;
  width: 2rem;
  height: 2rem;
  color: ${colors.dark};
`;

export const UserIcon = styled(User).attrs({
  color: 'dark',
})`
  display: flex;
  align-self: center;
  width: 2rem;
  height: 2rem;
  color: ${colors.dark};
`;

export const TextArea = styled.div`
  background-color: ${colors.light};
  padding: ${spacing.small} ${spacing.medium};
  text-align: start;
  border-radius: ${radius.medium};
  border: none;
  // 100% - icon container width - gap
  width: calc(100% - 3.5rem - ${spacing.small});
  max-height: 3.5rem;
  height: 100%;
  cursor: pointer;

  ${media.greaterThan('tablet')`
    background-color: transparent;
    padding: 0;
    width: 100%;
  `}
`;

export const Typography = styled(PantheonTypography).attrs({
  variant: 'body_text_3',
  marginBottom: 'none',
})`
  line-height: 1.2;
`;

export const QuestionCircleIcon = styled(QuestionCircle).attrs({
  color: 'dark',
})`
  display: flex;
  align-self: center;
  justify-self: center;
  width: 2rem;
  height: 2rem;
  color: ${colors.dark};
`;
