import { createContext, useContext, useEffect, useState } from 'react';

import { useIsWidget } from 'hooks/useIsWidget';

import { EventName, triggerWidgetEvent } from 'utils/triggerWidgetEvent';

import { defaultStyles } from './defaultStyles';
import { useCustomStyles } from './useCustomStyles';
import { validateStyles } from './validateStyles';

export interface ThemeProps {
  primaryColor: string;
  textColor: string;
}

interface WidgetCtx {
  theme: ThemeProps;
}

const Widget = createContext({} as WidgetCtx);

const LAMI_WIDGET_STYLES = 'lamiWidgetStyles';

export const WidgetProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [theme, setTheme] = useState<ThemeProps>(defaultStyles);
  const { isWidget, isWidgetFirstRender } = useIsWidget();
  const { widgetCustomStyles } = useCustomStyles();

  useEffect(() => {
    const validatedStyles = validateStyles(widgetCustomStyles);

    if (!isWidgetFirstRender) return;

    localStorage.setItem(
      LAMI_WIDGET_STYLES,
      JSON.stringify({ ...defaultStyles, ...validatedStyles }),
    );

    setTheme((current) => ({ ...current, ...validatedStyles }));

    // NOTE: This check is necessary to avoid triggering onLoadFinish message when the styles object is still empty
    const isCustomStylesEmpty = Object.keys(widgetCustomStyles).length === 0;

    if (isCustomStylesEmpty) {
      return;
    }

    // NOTE: Here we are checking if the partner sent custom styles or not before sending the onLoadFinish message
    const hasStylesChanged =
      JSON.stringify(defaultStyles) !== JSON.stringify(validatedStyles);

    if (!hasStylesChanged) {
      triggerWidgetEvent({
        eventName: EventName.onLoadFinish,
        body: validatedStyles,
      });

      return;
    }

    triggerWidgetEvent({
      eventName: EventName.onLoadFinish,
      body: validatedStyles,
    });
  }, [isWidgetFirstRender, widgetCustomStyles]);

  useEffect(() => {
    if (!isWidget) return;

    const localConfig = JSON.parse(
      localStorage.getItem(LAMI_WIDGET_STYLES) as string,
    ) as ThemeProps;

    if (localConfig) {
      setTheme((current) => ({ ...current, ...localConfig }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Widget.Provider value={{ theme }}>{children}</Widget.Provider>;
};

export const useWidgetConfig = () => {
  const context = useContext(Widget);

  if (!context) {
    throw new Error('useWidgetConfig hook must be used under WidgetProvider');
  }

  return context;
};
