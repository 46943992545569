import { validateHexColor } from 'utils/validateHexColor';

import { defaultStyles } from '../defaultStyles';

export const validatePrimaryColor = (primaryColor?: string) => {
  if (!primaryColor) return defaultStyles.primaryColor;

  if (!validateHexColor(primaryColor)) {
    console.error('Invalid primaryColor: insert a valid HEX color code.');

    return defaultStyles.primaryColor;
  }

  return primaryColor;
};
