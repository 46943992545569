import Router from 'next/router';

export function redirectToSso(lang?: string) {
  const params = new URL(document.location.href).searchParams;
  const queryLang = params.get('lang') || params.get('locale');

  const url = new URL(
    `${process.env.NEXT_PUBLIC_SSO_URL || 'https://auth.lamimed.it'}`,
  );

  url.searchParams.set('origin', window.location.href);

  url.searchParams.set('lang', queryLang || lang || 'it');

  Router.push(url);
}
