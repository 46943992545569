import React from 'react';
import { RoundButton } from '@lamimed/pantheon';

import { useMenu } from 'hooks/useMenu';

import * as S from './styles';

export interface MobileWrapperProps {
  isOpen?: boolean;
}

export const MobileMenuWrapper: React.FC<
  React.PropsWithChildren<MobileWrapperProps>
> = ({ isOpen, children }) => {
  const { handleCloseMenu } = useMenu();

  return (
    <S.MobileMenuWrapper isOpen={isOpen}>
      {children}

      <S.MenuBackground onClick={handleCloseMenu} />

      <S.Footer>
        <RoundButton shrinked onClick={handleCloseMenu} icon="close" />
      </S.Footer>
    </S.MobileMenuWrapper>
  );
};
