import Router from 'next/router';

import { b2bUrl } from './links';

export enum ErrorRedirect {
  notFound,
  internalError,
}

const errorStatus = {
  [ErrorRedirect.notFound]: '404',
  [ErrorRedirect.internalError]: '500',
};

export const errorRedirect = (error: ErrorRedirect) =>
  Router.push(`${b2bUrl}/${errorStatus[error]}`);
