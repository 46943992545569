import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { ThemeProps } from '.';

export const useCustomStyles = () => {
  const [widgetCustomStyles, setWidgetCustomStyles] = useState(
    {} as ThemeProps,
  );
  const {
    isReady,
    query: { primaryColor, textColor },
  } = useRouter();

  useEffect(() => {
    if (!isReady) {
      return;
    }

    setWidgetCustomStyles({
      primaryColor: primaryColor as string,
      textColor: textColor as string,
    });
  }, [primaryColor, textColor, isReady]);

  return { widgetCustomStyles };
};
