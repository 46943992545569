import { PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import ErrorPage from 'pages/500';

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <ReactErrorBoundary
      fallbackRender={ErrorPage}
      onError={(error: Error, info: { componentStack: string }) => {
        datadogRum.addError(error, {
          where: info.componentStack,
          context: 'ErrorBoundary',
          error: {
            message: error.message,
          },
        });
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};
