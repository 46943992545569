import { gap, layers, media, spacing } from '@lamimed/pantheon';
import Image from 'next/legacy/image';
import styled from 'styled-components';

import {
  headerHeightDesktop,
  headerHeightMobile,
} from 'components/Header/styles';

export const Hero = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(${spacing.xlarge} + ${headerHeightMobile});

  ${media.greaterThan('tablet')`
    margin-top: calc(${spacing.xlarge} + ${headerHeightDesktop});
  `}
`;

export const LinkProxy = styled.a`
  text-decoration: none;
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.medium};
  text-align: center;

  ${gap(spacing.medium)};

  > * {
    width: 100%;
  }

  ${media.greaterThan('tablet')`
    margin-bottom: ${spacing.large};
  `}
`;

export const HeroImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ErrorImage = styled(Image)`
  z-index: ${layers.under};
`;

export const CatImage = styled.img`
  position: absolute;
  top: -7%;
  right: 0;
  left: 46%;
  transform: translate(-50%, 0);
  z-index: ${layers.default};

  ${media.greaterThan('tablet')`
    top: 0;
  `}
`;
