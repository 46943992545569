import Cookies from 'js-cookie';

import { useIsWidget } from 'hooks/useIsWidget';

export const useCookie = () => {
  const { isWidget } = useIsWidget();

  const set = (key: string, value: string) => {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
      Cookies.set(key, value);

      return;
    }

    if (isWidget) {
      Cookies.set(key, value, { sameSite: 'none', secure: true });

      return;
    }

    Cookies.set(key, value);
  };

  const get = (key: string) => Cookies.get(key);

  const remove = (key: string) => Cookies.remove(key);

  return { set, get, remove };
};
