import {
  colors,
  layers,
  media,
  NewTypography as PantheonTypography,
  radius,
  spacing,
} from '@lamimed/pantheon';
import Link from 'next/link';
import styled from 'styled-components';

import { headerHeightMobile } from '../../styles';

interface UserProps {
  isOpen?: boolean;
}

export const UserMenuContent = styled.div<UserProps>`
  display: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};
  padding: ${spacing.medium};
  border-radius: 0 0 ${radius.medium} ${radius.medium};
  background-color: ${colors.dark};
  z-index: ${layers.modal};
  top: ${headerHeightMobile};
  position: fixed;
  width: 100%;
  left: 0;

  ${media.greaterThan('tablet')`
    position: absolute;
    background-color: ${colors.dark};
    border-radius: 0 0 ${radius.small} ${radius.small};
    z-index: ${layers.menu};
    width: 100%;
    top: calc(${spacing.medium} + ${spacing.small});
    display: flex;
    flex-direction: column;
    padding: ${spacing.small} 0 ${spacing.medium} 0;
    left: unset;
  `}
`;

export const Typography = styled(PantheonTypography).attrs({
  variant: 'body_text_3',
  marginBottom: 'none',
})`
  line-height: 1;

  ${media.greaterThan('tablet')`
    text-align: center;
  `}
`;

export const Anchor = styled(Link)`
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
`;
