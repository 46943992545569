import {
  breakpoints,
  Button,
  colors,
  gap,
  gutter,
  layers,
  media,
  radius,
  shadows,
  spacing,
  Typography,
} from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

interface WrapperProps {
  isOpen: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ isOpen }) => css`
    align-items: center;
    background-color: ${colors.light};
    border-radius: ${radius.medium};
    bottom: ${gutter.default};
    margin-bottom: ${spacing.large};
    box-shadow: ${shadows.default};
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    left: 50%;
    max-width: calc(100% - ${gutter.default} * 2);
    opacity: 0;
    padding: ${gutter.default};
    pointer-events: none;
    position: fixed;
    transform: translate(-50%, 2rem);
    transition: all 0.25s ease;
    visibility: hidden;
    width: 100%;
    z-index: ${layers.modal + 1};

    ${gap(gutter.default)};

    ${media.greaterThan('tablet')`
      flex-direction: row;
      max-width: calc(${breakpoints.desktop} - (${gutter.default} * 2));
    `}

    ${!!isOpen &&
    css`
      opacity: 1;
      pointer-events: initial;
      transform: translate(-50%, 0);
      transition: all 0.5s ease;
      visibility: visible;
    `}
  `}
`;

export const Disclaimer = styled(Typography)`
  font-size: 1.2rem;
  font-style: italic;
  margin-top: ${gutter.default};
`;

export const AcceptButton = styled(Button)`
  white-space: nowrap;
`;
