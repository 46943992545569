import { useViewport } from '@lamimed/pantheon';

import * as S from './styles';

export const RequestADemo = () => {
  const { greaterThan } = useViewport();

  return (
    <S.ContentContainer>
      <S.IconContainer>
        <S.FileMarkIcon />
      </S.IconContainer>

      <S.TextArea>
        <S.Typography
          variant="body_text_3"
          marginBottom="none"
          color={greaterThan('tablet') ? 'text__light' : 'text__primary'}
        >
          Richiedi una demo
        </S.Typography>
      </S.TextArea>
    </S.ContentContainer>
  );
};

export const WhoWeAre = () => (
  <S.ContentContainer mobileMarginBottom="small">
    <S.IconContainer>
      <S.UsersIcon />
    </S.IconContainer>

    <S.TextArea>
      <S.Typography variant="body_text_3" marginBottom="none">
        Chi siamo
      </S.Typography>
    </S.TextArea>
  </S.ContentContainer>
);

export const PatientArea = () => (
  <S.ContentContainer mobileMarginBottom="none">
    <S.IconContainer>
      <S.UserIcon />
    </S.IconContainer>

    <S.TextArea>
      <S.Typography variant="body_text_3" marginBottom="none">
        Area paziente
      </S.Typography>
    </S.TextArea>
  </S.ContentContainer>
);

export const Support = () => {
  const { greaterThan } = useViewport();

  return (
    <S.ContentContainer mobileMarginTop="small">
      <S.IconContainer>
        <S.QuestionCircleIcon />
      </S.IconContainer>

      <S.TextArea>
        <S.Typography
          variant="body_text_3"
          marginBottom="none"
          color={greaterThan('tablet') ? 'text__light' : 'text__primary'}
        >
          Supporto
        </S.Typography>
      </S.TextArea>
    </S.ContentContainer>
  );
};
