import {
  colors,
  FileEarmarkCheckFill,
  gap,
  Grid,
  layers,
  media,
  NewTypography as PantheonTypography,
  radius,
  RoundButton as RoundButtonParent,
  shadows,
  spacing,
  User,
  Users,
} from '@lamimed/pantheon';
import { Menu as StyledIconsMenu } from '@styled-icons/open-iconic/Menu';
import styled, { css } from 'styled-components';

import { MenuIcon as MenuIconComponent } from 'components/Icons';

export const headerHeightMobile = '5rem';
export const headerHeightDesktop = '6rem';

import { HeaderProps as IHeaderProps } from './';

interface HeaderProps {
  isOpen?: boolean;
  isActive?: boolean;
  isOnSmallScreen?: boolean;
  variant?: IHeaderProps['variant'];
}

export const Navigation = styled.nav<HeaderProps>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${layers.menu};
  background-color: ${colors.light};
  box-shadow: ${shadows.level1};

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${media.lessThan('tablet')`
        background-color: ${colors.dark};
      `}
    `}

  ${({ variant }) =>
    variant === 'second' &&
    css`
      ${media.greaterThan('tablet')`
        background-color: transparent;
        box-shadow: unset;
      `}
    `}
`;

export const Wrapper = styled(Grid.Wrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${headerHeightMobile};
  justify-content: flex-end;

  ${media.greaterThan('tablet')`
    ${gap(spacing.large)};
    height: ${headerHeightDesktop};
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
`;

export const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const GridWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${gap(spacing.medium)};
`;

export const MenuContainer = styled.div`
  grid-area: menu;
  align-self: center;
  justify-self: end;
`;

export const EmptyMenuContainer = styled.div`
  grid-area: empty;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
`;

export const Anchor = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const Typography = styled(PantheonTypography).attrs({
  variant: 'body_text_3',
  marginBottom: 'none',
})`
  line-height: 1.2;
  color: ${colors.dark};
`;

export const UserIcon = styled(User)`
  color: ${colors.dark};
`;

export const MenuIcon = styled(MenuIconComponent)`
  color: ${colors.dark};
  display: flex;
  align-self: center;
  justify-self: center;

  :hover {
    color: ${colors.light};
  }
`;

export const MobileMenuIcon = styled(StyledIconsMenu)`
  width: 1.8rem;
  height: 1.6rem;
  color: ${colors.dark};
`;

export const MenuButton = styled.button<HeaderProps>`
  position: relative;
  height: 2.5rem;
  padding-left: ${spacing.small};
  padding-right: ${spacing.small};
  background-color: transparent;
  border: none;
  border-radius: ${radius.medium};

  ${media.lessThan('tablet')`
    width: 4rem;
    height: 2.5rem;

    :hover {
      background-color: ${colors.light};
      border: none;
      border-radius: ${radius.medium};
      align-self: center;
      justify-self: end;

      /** NOTE: This svg tag is referent to the icons on the menu, they can be the menu icon, the user icon or the telephone icon */
      > svg {
        color: ${colors.dark};
      }
    }
  `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${media.lessThan('tablet')`
        > svg {
          color: ${colors.light};
        }
      `}
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.light};

      > svg {
        color: ${colors.dark};
      }
    `}

    ${({ isOpen, isActive }) =>
    isOpen &&
    isActive &&
    css`
      > svg {
        color: ${colors.dark};
      }
    `}
`;

export const HiddenMenu = styled.div`
  display: none;
  top: ${spacing.small};
  z-index: ${layers.menu};
  position: absolute;

  ${media.lessThan('tablet')`
    ${MenuButton} {
      display: none;
    }
  `}

  ${media.greaterThan('tablet')`
    width: unset;

    ${Typography} {
      color: ${colors.light};
    }

    ${MenuButton} {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: ${colors.dark};
      border-radius: ${radius.small} ${radius.small} 0 0;

      /** NOTE: This svg tag is referent to the icons on the menu, they can be the menu icon, the user icon or the telephone icon */
      > svg {
        color: ${colors.light};
      }
    }
  `}
`;

export const Menu = styled.div<HeaderProps>`
  height: 100%;
  border-radius: ${radius.small};

  :hover {
    ${HiddenMenu} {
      display: block;
    }
  }

  ${({ isOpen, isActive }) =>
    isOpen &&
    !isActive &&
    css`
      ${media.lessThan('tablet')`
        ${HiddenMenu} {
          display: block;
        }

        ${MenuIcon} {
          color: ${colors.light};
        }
      `}
    `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.small};
  max-height: 3.5rem;
  height: 100%;
  text-decoration: none;
`;

export const IconContainer = styled.div`
  background-color: ${colors.light};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: calc(${spacing.small} / 2);
  height: 3.5rem;
  width: 3.5rem;
  margin-right: ${spacing.small};
`;

export const TextArea = styled.div`
  background-color: ${colors.light};
  padding: ${spacing.small} ${spacing.medium};
  text-align: start;
  border-radius: ${radius.medium};
  border: none;
  // 100% - icon container width - gap
  width: calc(100% - 3.5rem - ${spacing.small});
  max-height: 3.5rem;
  height: 100%;
  cursor: pointer;

  ${media.greaterThan('tablet')`
    background-color: transparent;
    padding: 0;
    width: 100%;
  `}
`;

export const FileMarkIcon = styled(FileEarmarkCheckFill).attrs({
  color: 'dark',
})`
  display: flex;
  align-self: center;
  justify-self: center;
  width: 2rem;
  height: 2rem;
  color: ${colors.dark};
`;

export const UsersIcon = styled(Users).attrs({
  color: 'dark',
})`
  display: flex;
  align-self: center;
  width: 2rem;
  height: 2rem;
  color: ${colors.dark};
`;

export const DesktopContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${gap(spacing.small)};
`;

export const RoundButton = styled(RoundButtonParent)`
  && {
    max-width: 22rem;
  }
`;
