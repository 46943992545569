import { gap, media, spacing } from '@lamimed/pantheon';
import styled from 'styled-components';

import { footerImageOffset } from 'components/Footer/styles';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: calc(${spacing.xlarge} + ${footerImageOffset});

  ${gap(spacing.medium)};

  ${media.greaterThan('tablet')`
    flex-direction: row;
    padding-bottom: calc(${spacing.xxlarge} + ${footerImageOffset});
  `};
`;
