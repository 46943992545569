import React from 'react';
import { MediaMatch, useViewport } from '@lamimed/pantheon';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { useMenu } from 'hooks/useMenu';

import { PatientArea, RequestADemo, WhoWeAre } from './containers/Content';
import { LinkButtons } from './containers/LinkButton';
import { Menu } from './containers/Menu';

import * as S from './styles';

export interface HeaderProps {
  variant?: 'first' | 'second';
}

export const Header = ({ variant = 'first' }: HeaderProps) => {
  const { greaterThan } = useViewport();
  const { isMenuOpen, openMenu, handleCloseMenu, handleToggleMenu } = useMenu();

  const logoImage = () => {
    if (isMenuOpen || (greaterThan('tablet') && variant === 'second'))
      return '/images/brand/brand-light.svg';

    return '/images/logo.svg';
  };

  return (
    <S.Navigation isOpen={isMenuOpen} variant={variant}>
      <S.Wrapper>
        <S.LogoContainer>
          <Link href="/" legacyBehavior>
            <S.Anchor>
              <Image
                src={logoImage()}
                alt="Logo"
                width={greaterThan('tablet') ? 58 : 52}
                height={greaterThan('tablet') ? 23 : 20}
                quality={100}
              />
            </S.Anchor>
          </Link>
        </S.LogoContainer>

        <S.NavigationContainer>
          <S.GridWrapper id="schedule-header">
            <MediaMatch lessThan="tablet">
              <S.EmptyMenuContainer onClick={handleCloseMenu} />

              <S.MenuContainer>
                <S.Menu isOpen={isMenuOpen}>
                  <S.MenuButton
                    onClick={handleToggleMenu}
                    isOpen={isMenuOpen}
                    isActive={openMenu}
                  >
                    <S.MobileMenuIcon />
                  </S.MenuButton>

                  <S.HiddenMenu>
                    <S.MenuButton>
                      <S.UserIcon width={16} height={18} />

                      <S.Typography>Login</S.Typography>
                    </S.MenuButton>

                    <Menu isOpen={openMenu} variant={variant} />
                  </S.HiddenMenu>
                </S.Menu>
              </S.MenuContainer>
            </MediaMatch>

            <MediaMatch greaterThan="tablet">
              {variant === 'first' && (
                <>
                  <Link href="/patient-area" passHref legacyBehavior>
                    <S.Anchor>
                      <PatientArea />
                    </S.Anchor>
                  </Link>

                  <Link href="/chi-siamo" passHref legacyBehavior>
                    <S.Anchor>
                      <WhoWeAre />
                    </S.Anchor>
                  </Link>

                  <Link href="/richiedi-una-demo" passHref legacyBehavior>
                    <S.RoundButton forwardedAs="a">
                      <RequestADemo />
                    </S.RoundButton>
                  </Link>
                </>
              )}

              {variant === 'second' && <LinkButtons />}
            </MediaMatch>
          </S.GridWrapper>
        </S.NavigationContainer>
      </S.Wrapper>
    </S.Navigation>
  );
};
