import { validatePrimaryColor } from '../validatePrimaryColor';
import { validateTextColor } from '../validateTextColor';

import { ThemeProps } from '..';

export const validateStyles = (themeOptions?: ThemeProps) => {
  const primaryColor = validatePrimaryColor(themeOptions?.primaryColor);
  const textColor = validateTextColor(themeOptions?.textColor);

  return { primaryColor, textColor };
};
