import { remToPx } from 'polished';

import {
  headerHeightDesktop,
  headerHeightMobile,
} from 'components/Header/styles';

export const useElementScroll = (isGreaterThanTablet?: boolean) => {
  const scrollToElement = (elementId: string, offset?: number) => {
    const bodyPosition = window.scrollY;

    const headerHeight = parseFloat(
      remToPx(isGreaterThanTablet ? headerHeightDesktop : headerHeightMobile),
    );

    const elementPosition = document
      .getElementById(elementId)
      ?.getBoundingClientRect().top;

    if (elementPosition) {
      const scrollPosition =
        elementPosition + bodyPosition - headerHeight - (offset ?? 0);

      window?.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const scrollToTop = () => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return {
    scrollToElement,
    scrollToTop,
  };
};
