import React, { useEffect, useMemo, useState } from 'react';
import { NewTypography as Typography, useViewport } from '@lamimed/pantheon';
import { ArrowUpCircleFill } from '@styled-icons/bootstrap/ArrowUpCircleFill';
import { PostcardHeart } from '@styled-icons/bootstrap/PostcardHeart';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { UserDoctor } from '@styled-icons/fa-solid/UserDoctor';
import { Facebook } from '@styled-icons/feather/Facebook';
import { Linkedin } from '@styled-icons/feather/Linkedin';
import { News } from '@styled-icons/fluentui-system-regular/News';
import { Policy } from '@styled-icons/material-outlined/Policy';
import { Email } from '@styled-icons/material-rounded/Email';
import { Phone } from '@styled-icons/remix-fill/Phone';
import { useRouter } from 'next/router';

import { useElementScroll } from 'hooks/useElementScroll';

import { ContentDivider } from 'components/ContentDivider';

import { FooterLink } from './FooterLink';

import * as S from './styles';

export const Footer: React.FC = () => {
  const [previousSectionColor, setPreviousSectionColor] = useState('');

  const { greaterThan } = useViewport();
  const { scrollToTop } = useElementScroll();

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const router = useRouter();

  useEffect(() => {
    const previousSection =
      document?.querySelector('#footer-wrapper')?.previousElementSibling;

    if (previousSection) {
      setPreviousSectionColor(
        getComputedStyle(previousSection).backgroundColor,
      );
    }
  }, [router]);

  return (
    <S.FooterWrapper id="footer-wrapper">
      <S.MainImageContainer backgroundColor={previousSectionColor}>
        <S.MainImage
          src="/images/footer/footer-img.png"
          alt="Doctor with lami logo"
          quality={100}
          width="137"
          height="127"
        />
      </S.MainImageContainer>

      <S.Footer>
        <S.Content>
          <S.LinksWrapper variant="doubleColumn">
            <FooterLink
              link="https://primarycare.lamimed.it/"
              variant="clear"
              alignment="center"
              fontSize="medium"
            >
              <Typography
                fontWeight="bold"
                marginBottom="none"
                variant="body_text_3"
              >
                Area associati
              </Typography>
            </FooterLink>

            <FooterLink
              link="/richiedi-una-demo"
              variant="outline"
              alignment="center"
              fontSize="medium"
            >
              <Typography
                fontWeight="bold"
                color="text__light"
                marginBottom="none"
                variant="body_text_3"
              >
                Richiedi una demo
              </Typography>
            </FooterLink>
          </S.LinksWrapper>

          <Typography
            variant="heading_4"
            color="text__light"
            marginBottom="medium"
          >
            Contatti
          </Typography>

          <S.LinksWrapper variant="multiColumn">
            <FooterLink link="mailto:info@lamimed.it" variant="clear">
              <Email />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                lineHeight="none"
              >
                info@lamimed.it
              </Typography>
            </FooterLink>

            <FooterLink link="tel:351 7559363" variant="clear">
              <Typography
                marginBottom="none"
                variant="body_text_3"
                lineHeight="none"
              >
                <Phone />
                351 7559363
              </Typography>
            </FooterLink>

            <FooterLink
              link="https://www.facebook.com/lami.homecare/"
              isIconStroke
            >
              <Facebook />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                lineHeight="none"
              >
                Facebook
              </Typography>
            </FooterLink>

            <FooterLink link="https://www.instagram.com/lamimed_/">
              <Instagram />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                lineHeight="none"
              >
                Instagram
              </Typography>
            </FooterLink>

            <FooterLink
              link="https://www.linkedin.com/company/lamimed/"
              isIconStroke
            >
              <Linkedin />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                lineHeight="none"
              >
                Linkedin
              </Typography>
            </FooterLink>
          </S.LinksWrapper>

          <Typography
            variant="heading_4"
            color="text__light"
            marginBottom="medium"
          >
            Scopri di più
          </Typography>

          <S.LinksWrapper>
            <FooterLink link="https://dottore.lamimed.it/">
              <UserDoctor />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                lineHeight="none"
              >
                Area Medico
              </Typography>
            </FooterLink>

            <FooterLink link="/privacy-policy">
              <Policy />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                lineHeight="none"
              >
                Privacy policy
              </Typography>
            </FooterLink>

            <FooterLink link="https://mailchi.mp/3101136d5799/lami">
              <News />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                lineHeight="none"
              >
                Newsletter
              </Typography>
            </FooterLink>

            <FooterLink link="/termini-condizioni">
              <PostcardHeart />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                lineHeight="none"
              >
                Termini e condizioni
              </Typography>
            </FooterLink>
          </S.LinksWrapper>

          <ContentDivider color="light" marginTop="none" marginBottom="large" />

          <S.FooterSectionWrapper>
            <S.Description color="text__light" marginBottom="large">
              <S.Span>Lami S.r.l., Via Ariberto, 8 – 20123 – Milano</S.Span>

              <S.Span>CF/PI 11507850961</S.Span>

              <S.Span>
                Ufficio registro imprese e n. Iscrizione MI - 2608290
              </S.Span>

              <S.Span>Capitale sociale 13.000,00 €</S.Span>

              <S.Span>Lami @ {currentYear}. Tutti i diritti riservati.</S.Span>
            </S.Description>

            <S.SendToTopButton onClick={scrollToTop}>
              <ArrowUpCircleFill />

              <Typography
                marginBottom="none"
                variant="body_text_3"
                color="text__light"
                fontWeight="bold"
              >
                Torna all’inizio
              </Typography>
            </S.SendToTopButton>
          </S.FooterSectionWrapper>
        </S.Content>

        <S.BrandSemiCircle
          src={
            greaterThan('tablet')
              ? '/images/footer/footer-desk-tab.png'
              : '/images/footer/footer-mobi.png'
          }
          alt="Footer Semi Circle"
          aria-hidden="true"
          fill
          quality={100}
        />
      </S.Footer>
    </S.FooterWrapper>
  );
};
