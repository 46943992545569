import { gap, NewTypography as Typography, spacing } from '@lamimed/pantheon';
import Link from 'next/link';
import styled, { css } from 'styled-components';

type ButtonProps = {
  marginLeft?: keyof typeof spacing;
};

export const Button = styled(Link)<ButtonProps>`
  ${({ marginLeft = 'none' }) => css`
    margin-left: ${spacing[marginLeft]};
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    ${gap(spacing.small)};
  `}
`;

export const Content = styled(Typography)`
  font-size: 1.4rem;
`;

export const Container = styled.div`
  display: flex;
`;
