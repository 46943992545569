import {
  borderWidths,
  colors,
  Grid,
  gutter,
  isMobileSmall,
  layers,
  media,
  NewTypography as Typography,
  radius,
  RoundButton,
  spacing,
} from '@lamimed/pantheon';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import { FooterLinkWrapper } from './FooterLink/styles';

export const footerImageOffset = '6.5rem';

export const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const flexColumn = () => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Footer = styled.footer`
  background: ${colors.dark};
  width: 100%;
  position: relative;

  ${flexColumn};
`;

interface MainImageContainerProps {
  backgroundColor: string;
}

export const MainImageContainer = styled.div<MainImageContainerProps>`
  ${({ backgroundColor }) => css`
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    z-index: ${layers.above};

    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: 80%;
      background-color: ${backgroundColor};
      z-index: ${layers.under};
      border-radius: 50%;
      transform: translateX(-50%);
      left: 50%;
    }
  `}
`;

export const MainImage = styled(Image)`
  object-fit: contain;
`;

const gridModifier = {
  singleColumn: css`
    ${media.greaterThan('tablet')`
      grid-template-columns: 1fr;
    `}
  `,
  doubleColumn: css`
    ${media.greaterThan('tablet')`
      grid-template-columns: 1fr 1fr;
    `}
  `,
  multiColumn: css`
    grid-template-columns: repeat(6, 1fr);

    ${FooterLinkWrapper} {
      grid-column: span 3;
    }

    ${FooterLinkWrapper}:nth-child(n + 3) {
      grid-column: span 2;
    }

    ${isMobileSmall()`

      gap: ${gutter.small};

      ${FooterLinkWrapper}:nth-child(3) {
        margin-top: ${spacing.small};
      }

      ${FooterLinkWrapper}:nth-child(n + 3) {
        grid-column: span 6;
      }
    `}
  `,
};

interface LinksWrapperProps {
  variant?: keyof typeof gridModifier;
}

export const LinksWrapper = styled.div<LinksWrapperProps>`
  ${({ variant = 'singleColumn' }) => css`
    display: grid;
    gap: ${gutter.small};

    width: 100%;
    margin-bottom: ${spacing.large};

    ${gridModifier[variant]};
  `}
`;

export const Content = styled(Grid.Wrapper).attrs(() => ({
  as: 'nav',
}))`
  ${flexColumn};

  max-width: 61rem;
  align-items: flex-start;
  padding-top: ${spacing.xlarge};
`;

export const ContentWrapper = styled.div`
  display: grid;
  width: 100%;

  a:not(:last-child) {
    margin-bottom: ${spacing.medium};
  }

  ${media.greaterThan('tablet')`
    width: 50%;
  `}
`;

export const Description = styled(Typography)`
  line-height: 1.5;
  text-align: center;
  font-size: 1.4rem;
`;

export const Span = styled.span`
  display: block;
`;

export const FooterSectionWrapper = styled.div`
  width: 100%;
  margin-bottom: ${spacing.large};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BrandSemiCircle = styled(Image)`
  object-fit: contain;
  position: initial !important;
  // NOTE: Crop the square edge of the semicircle image
  object-position: 0 0.4rem;

  ${media.greaterThan('tablet')`
    object-position: 0 1rem;
    max-width: 120rem !important;
    height: auto !important;
  `};
`;

export const SendToTopButton = styled(RoundButton).attrs({
  variant: 'transparent',
})`
  min-width: unset !important;
  width: fit-content !important;
  height: 4rem;
  border-radius: ${radius.small};
  padding: ${spacing.small} !important;
  border: ${borderWidths.thin} solid ${colors.light};

  & span {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  & svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: ${spacing.small};
    fill: ${colors.light};

    stroke: none;

    & path {
      fill: ${colors.light};
    }
  }

  & p {
    font-size: 1rem;
  }
`;
