import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import type { Location } from 'models/Location';

import { useCookie } from 'hooks/useCookie';
import { useSWRGet } from 'hooks/useSWRGet';

interface CitySelectionCtx {
  selectedCity?: string;
  location?: Location;
  fillSelectedCity: (cityId: string) => void;
  removeSelectedCity: () => void;
}

const CitySelectionContext = createContext({} as CitySelectionCtx);

const cookieKey = 'selected_city';

export const CitySelectionProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { query } = useRouter();
  const { get, set, remove } = useCookie();

  const routerSelectedCity = query?.selectedCity as string;

  const [selectedCity, setSelectedCity] = useState('');

  const { data: locationData } = useSWRGet<Location>(
    selectedCity && `/locations/${selectedCity}`,
  );

  useEffect(() => {
    if (query?.selectedCity) {
      setSelectedCity(routerSelectedCity);

      set(cookieKey, routerSelectedCity);

      return;
    }

    const cookieValue = get(cookieKey) as string;

    if (cookieValue) {
      setSelectedCity(cookieValue);
    }
  }, [get, set, query, routerSelectedCity]);

  const fillSelectedCity = (cityId: string) => {
    setSelectedCity(cityId);

    set(cookieKey, cityId);
  };

  const removeSelectedCity = () => {
    remove(cookieKey);

    setSelectedCity('');
  };

  return (
    <CitySelectionContext.Provider
      value={{
        selectedCity,
        location: locationData,
        fillSelectedCity,
        removeSelectedCity,
      }}
    >
      {children}
    </CitySelectionContext.Provider>
  );
};

export const useCitySelection = () => {
  const context = useContext(CitySelectionContext);

  if (!context) {
    throw new Error(
      'useCitySelection hook must be used under CitySelectionProvider',
    );
  }

  return context;
};
