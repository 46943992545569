import { colors, CSSReset as PantheonReset, spacing } from '@lamimed/pantheon';
import { createGlobalStyle } from 'styled-components';

const CSSReset = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    overflow-x: hidden;
  }

  #launcher {
    right: ${spacing.small} !important;
    bottom: 1.6rem !important;
    margin: 0 !important;
  }

  .lucide {
    width: 1.8rem;
    height: 1.8rem;
    color: ${colors.dark};
    stroke-width: 1;
  }
`;

export const GlobalStyles = () => (
  <>
    <PantheonReset />

    <CSSReset />
  </>
);
