export enum EventName {
  onLoadFinish = 'onLoadFinish',
  onLoadStart = 'onLoadStart',
  onAppointmentFinish = 'onAppointmentFinish',
  close = 'close',
}

export const triggerWidgetEvent = ({
  eventName,
  body,
  origin,
}: {
  eventName: EventName;
  body?: unknown;
  origin?: string;
}) => {
  if (!window && !document) return;

  const isWidget = !(window === window.parent || window.opener);

  if (!isWidget) return;

  const getSupportedOrigin = () => {
    if (document.location.ancestorOrigins?.length > 0)
      return document.location.ancestorOrigins[0];

    if (origin) return origin;

    return '*';
  };

  window.parent.postMessage(
    {
      functionName: eventName,
      body,
    },
    getSupportedOrigin(),
  );
};
