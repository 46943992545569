import type { Service } from './axiosFactory';

export function getExternalUrl(service?: Service) {
  switch (service) {
    case 'exams':
      return process.env.NEXT_PUBLIC_BACKEND_URL || 'https://api.lamimed.it';
    case 'attendence':
      return (
        process.env.NEXT_PUBLIC_BACKEND_ATTENDENCE_SERVICE ||
        'https://api-attendance.lamimed.it'
      );
    case 'get-in-touch':
      return (
        process.env.NEXT_PUBLIC_BACKEND_GET_IN_TOUCH_API ||
        'https://get-in-touch-api.lamimed.it'
      );
    case 'symptom-checker':
      return (
        process.env.NEXT_PUBLIC_BACKEND_SYMPTOM_CHECKER_API ||
        'https://symptom-checker-api.lamimed.it'
      );
    case 'payment-method':
      return (
        process.env.NEXT_PUBLIC_PAYMENT_METHOD_API ||
        'https://5umjap5ekj.execute-api.us-east-1.amazonaws.com'
      );
    case 'discount-handler':
      return (
        process.env.NEXT_PUBLIC_DISCOUNT_HANDLER_API ||
        'https://s0ib03toq2.execute-api.us-east-1.amazonaws.com'
      );
    case 'confirm-appointment-handler':
      return (
        process.env.NEXT_PUBLIC_CONFIRM_APPOINTMENT_HANDLER_API ||
        'https://taeogyymc0.execute-api.us-east-1.amazonaws.com'
      );
    case 'symptom-checker-frontend':
      return (
        process.env.NEXT_PUBLIC_FRONTEND_SYMPTOM_CHECKER ||
        'https://primarycare.lamimed.it'
      );
    default:
      return process.env.NEXT_PUBLIC_BACKEND_URL || 'https://api.lamimed.it';
  }
}
