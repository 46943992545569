import { colors, spacing } from '@lamimed/pantheon';

import * as S from './styles';

export interface ContentDividerProps {
  color?: keyof typeof colors;
  marginTop?: keyof typeof spacing;
  marginBottom?: keyof typeof spacing;
}

export const ContentDivider = ({
  color = 'neutral_light',
  marginBottom = 'small',
  marginTop = 'small',
}: ContentDividerProps) => {
  return (
    <S.Divider
      color={color}
      marginBottom={marginBottom}
      marginTop={marginTop}
    />
  );
};
