import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';

import { useStorage } from 'hooks/useStorage';

import { parseJsonWithDefault } from '../../utils/parseJsonWithDefault';

export const PARTNER_ID_STORAGE_KEY = 'partnerId';

export const usePartnerId = () => {
  const { get, set } = useStorage();

  const router = useRouter();

  const {
    query: { partnerId },
  } = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const storagePartnerId = parseJsonWithDefault(
        get(PARTNER_ID_STORAGE_KEY),
        process.env.NEXT_PUBLIC_DEFAULT_PARTNER_ID,
      );
      const routerPartnerId = partnerId as string;

      if (!!routerPartnerId && storagePartnerId !== routerPartnerId) {
        set(PARTNER_ID_STORAGE_KEY, routerPartnerId);

        datadogRum.addAction('setPartnerId', {
          where: 'usePartnerId',
          context: { partnerId: partnerId },
        });

        return;
      }

      if (!storagePartnerId && !routerPartnerId) {
        const defaultPartnerId =
          process.env.NEXT_PUBLIC_DEFAULT_PARTNER_ID || '';

        set(PARTNER_ID_STORAGE_KEY, defaultPartnerId);

        datadogRum.addAction('setDefaultPartnerId', {
          where: 'usePartnerId',
          context: { partnerId: partnerId },
        });

        return;
      }
    }
  }, [set, get, router, partnerId]);

  return {
    partnerId: parseJsonWithDefault(
      get(PARTNER_ID_STORAGE_KEY),
      process.env.NEXT_PUBLIC_DEFAULT_PARTNER_ID,
    ) as string,
  };
};
