export const useStorage = () => {
  const set = (key: string, value: unknown) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  const get = (key: string): string => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key) as string;
    }

    return JSON.stringify('');
  };

  const remove = (key: string) => {
    if (typeof window !== 'undefined') {
      return localStorage.removeItem(key);
    }
  };

  return {
    set,
    get,
    remove,
  };
};
