import React from 'react';

import { useMenu } from 'hooks/useMenu';

import {
  whatsappMessage,
  whatsappNumber,
  whatsappUrl,
} from 'utils/whatsappUrl';

import { HeaderProps } from 'components/Header';

import { PatientArea, RequestADemo, Support, WhoWeAre } from '../Content';
import { MobileMenuWrapper } from '../MobileMenuWrapper';

import * as S from './styles';

interface UserProps {
  isOpen: boolean;
  variant: HeaderProps['variant'];
}

export const Menu: React.FC<UserProps> = ({ isOpen, variant }) => {
  const { handleCloseMenu } = useMenu();

  return (
    <MobileMenuWrapper isOpen={isOpen}>
      <S.UserMenuContent isOpen={isOpen}>
        {variant === 'first' && (
          <S.Anchor onClick={handleCloseMenu} href="/richiedi-una-demo">
            <RequestADemo />
          </S.Anchor>
        )}

        <S.Anchor href="/chi-siamo" onClick={handleCloseMenu}>
          <WhoWeAre />
        </S.Anchor>

        <S.Anchor href="/patient-area" onClick={handleCloseMenu}>
          <PatientArea />
        </S.Anchor>

        {variant === 'second' && (
          <S.Anchor
            onClick={handleCloseMenu}
            href={whatsappUrl(whatsappNumber.default, whatsappMessage.default)}
            target="_blank"
          >
            <Support />
          </S.Anchor>
        )}
      </S.UserMenuContent>
    </MobileMenuWrapper>
  );
};
