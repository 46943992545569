import { borderWidths, colors, spacing } from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

import { ContentDividerProps } from '.';

export const Divider = styled.hr<Required<ContentDividerProps>>`
  ${({ color, marginBottom, marginTop }) => css`
    width: 100%;
    margin: ${spacing[marginTop]} 0 ${spacing[marginBottom]} 0;
    border: 0;
    border-top: ${borderWidths.thin} solid ${colors[color]};
    background-color: ${colors[color]};
  `}
`;
