import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useSWR from 'swr';

import { axiosFactory, Service } from 'utils/http/axiosFactory';

export function useSWRGet<T>(
  targetUrl?: string | false,
  service?: Service,
  mockedUrl?: string,
) {
  const { i18n } = useTranslation();

  const fetcher = axiosFactory(i18n.language, service);

  const { data, error, isValidating, mutate } = useSWR<T>(
    targetUrl ?? null,
    async (url) => {
      if (mockedUrl) {
        const responseWithMockedUrl = await axios.get<T>(`${mockedUrl}${url}`);

        return responseWithMockedUrl.data;
      }

      const response = await fetcher.get<T>(url);

      return response.data;
    },
  );

  return { data, error, isValidating, mutate };
}
