import { DiscountType } from './Discount';

export enum Status {
  active = 'ACTIVE',
  disable = 'DISABLED',
  finished = 'FINISHED',
}

export interface Coupon {
  status: Status;
  couponId: string;
  couponCode: string | null;
  couponAmount: number;
  finalAmount: number;
  originalAmount: number;
  type: DiscountType;
}
