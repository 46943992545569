import { PropsWithChildren } from 'react';

import * as S from './styles';

export interface FooterLinkProps extends PropsWithChildren {
  variant?: 'default' | 'outline' | 'clear';
  link: string;
  alignment?: 'center' | 'left';
  width?: string;
  fontSize?: 'small' | 'default' | 'medium';
  isIconStroke?: boolean;
  onClick?: () => void;
}

export const FooterLink: React.FC<FooterLinkProps> = ({
  variant = 'default',
  alignment = 'left',
  link,
  width,
  fontSize,
  isIconStroke = false,
  onClick,
  children,
}) => {
  return (
    <S.FooterLinkWrapper
      href={link}
      variant={variant}
      alignment={alignment}
      width={width}
      fontSize={fontSize}
      isIconStroke={isIconStroke}
      onClick={onClick}
    >
      {children}
    </S.FooterLinkWrapper>
  );
};
