import { QuestionCircle, Users } from '@lamimed/pantheon';

import {
  whatsappMessage,
  whatsappNumber,
  whatsappUrl,
} from 'utils/whatsappUrl';

import * as S from './styles';

export const LinkButtons = () => {
  return (
    <S.Container>
      <S.Button href="/chi-siamo">
        <S.Content color="neutral" marginBottom="none">
          Chi siamo
        </S.Content>

        <Users color="dark" />
      </S.Button>

      <S.Button
        marginLeft="medium"
        href={whatsappUrl(whatsappNumber.default, whatsappMessage.default)}
        target="_blank"
      >
        <S.Content color="neutral" marginBottom="none">
          Supporto
        </S.Content>

        <QuestionCircle color="dark" />
      </S.Button>
    </S.Container>
  );
};
