import React, { ReactElement, ReactNode, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { datadogRum } from '@datadog/browser-rum';
import { ViewportProvider } from '@lamimed/pantheon';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { GlobalScripts } from 'scripts';
import { StyleSheetManager } from 'styled-components';
import { GlobalStyles } from 'styles/global';

import { CitySelectionProvider } from 'hooks/useCitySelection';
import { CouponProvider } from 'hooks/useCoupon';
import { IsWidgetProvider } from 'hooks/useIsWidget';
import { useLamiId } from 'hooks/useLamiId';
import { WidgetProvider } from 'hooks/useWidgetStyles';

import { DefaultLayout } from 'layouts/default';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { Footer } from 'components/Footer';
import { WidgetThemeProvider } from 'components/wrappers/ThemeProvider';

import { CookiesWarning } from 'partials/CookiesWarning';
import { HeightControlContainer } from 'partials/HeightControlContainer';

import nextI18nConfig from '../../next-i18next.config';

type LayoutNextPage = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type LayoutAppProps = AppProps & {
  Component: LayoutNextPage;
};

function App({ Component, pageProps }: LayoutAppProps) {
  const { getLamiId } = useLamiId();

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-PZM9NR3',
    });
  }, []);

  const key = getLamiId();

  const getLayout =
    Component.getLayout ??
    ((page) => <DefaultLayout footer={<Footer />}>{page}</DefaultLayout>);

  if (process.env.NODE_ENV === 'production') {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
      service: process.env.NEXT_PUBLIC_DATADOG_APP || 'website',
      version: process.env.NEXT_PUBLIC_DATADOG_APP_VERSION || '1.1.0',
      site: 'datadoghq.eu',
      env: 'production',
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
      silentMultipleInit: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
    });
  }

  return (
    <StyleSheetManager enableVendorPrefixes>
      <Head>
        <title>Lami: Health delivery!</title>

        <link rel="shortcut icon" href="/images/icon-512.png" />

        <link rel="apple-touch-icon" href="/images/icon-apple.png" />

        <link rel="manifest" href="/manifest.json" />

        <link rel="preload" as="image" href="/images/brand/brand-light.svg" />

        <meta name="format-detection" content="telephone=no" />

        <meta name="theme-color" content="#ffffff" />

        <meta
          name="description"
          content="Prenota un servizio medico a domicilio in pochi passi. Massima attesa 24 ore!"
        />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <GlobalStyles />

      <ErrorBoundary>
        <LDProvider
          clientSideID={
            process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT ||
            process.env.NODE_ENV === 'production'
              ? '616d473b42348e0d643c2b01'
              : '616d473b42348e0d643c2b00'
          }
          // The email property is required to launchdarkly list users
          // properly on dashboard for unkown reasons right now.
          context={{
            kind: 'user',
            key,
            email: `${key}@lamimed.it`,
          }}
        >
          <ViewportProvider>
            <CitySelectionProvider>
              <CouponProvider>
                <IsWidgetProvider>
                  <WidgetProvider>
                    <WidgetThemeProvider>
                      {getLayout(<Component {...pageProps} />)}
                    </WidgetThemeProvider>
                  </WidgetProvider>

                  <CookiesWarning />
                </IsWidgetProvider>
              </CouponProvider>
            </CitySelectionProvider>
          </ViewportProvider>
        </LDProvider>
      </ErrorBoundary>

      {/* NOTE: The object of this is to serve as a "ruler" so we can measure the height of the device including the address bar height. */}

      {/* More details at: https://lamimed.atlassian.net/browse/TEC-994 */}

      <HeightControlContainer />

      <GlobalScripts />
    </StyleSheetManager>
  );
}

export default appWithTranslation(App, nextI18nConfig);
