export const whatsappMessage = {
  default: 'Ciao ho bisogno del vostro supporto',
};

export const whatsappNumber = {
  default: '17275925942',
};

export const whatsappUrl = (phone: string, message: string) => {
  return `https://wa.me/${phone}?text=${message}`;
};
