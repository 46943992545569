import axios, { AxiosError } from 'axios';

import { PARTNER_ID_STORAGE_KEY } from 'hooks/usePartnerId';

import { redirectToSso } from 'utils/redirectToSso';

import { parseJsonWithDefault } from '../parseJsonWithDefault';

import { getExternalUrl } from './getExternalUrl';
import { getFingerprint } from './getFingerprint';

export type Service =
  | 'exams'
  | 'attendence'
  | 'get-in-touch'
  | 'symptom-checker'
  | 'symptom-checker-frontend'
  | 'payment-handler'
  | 'payment-method'
  | 'discount-handler'
  | 'confirm-appointment-handler';

export const axiosFactory = (lang?: string, service?: Service) => {
  const baseUrl = getExternalUrl(service);

  switch (service) {
    case 'exams':
      return axios.create({
        baseURL: baseUrl,
      });

    case 'attendence': {
      if (typeof window === 'undefined')
        return axios.create({
          headers: {
            'partner-id': '',
          },
          baseURL: '',
        });

      const partnerId = parseJsonWithDefault(
        localStorage.getItem(PARTNER_ID_STORAGE_KEY) as string,
        process.env.NEXT_PUBLIC_DEFAULT_PARTNER_ID,
      );

      const axiosInstance = axios.create({
        headers: {
          'partner-id': partnerId,
          'fingerprint': getFingerprint(),
        },
        baseURL: baseUrl,
      });

      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error: AxiosError) => {
          if (
            error.response?.status &&
            [401, 403].includes(error.response.status)
          ) {
            redirectToSso(lang);
          }

          return Promise.reject(error);
        },
      );

      return axiosInstance;
    }
    case 'get-in-touch':
      return axios.create({
        baseURL: baseUrl,
      });

    case 'symptom-checker': {
      return axios.create({
        baseURL: baseUrl,
        headers: {
          fingerprint: getFingerprint(),
        },
      });
    }
    case 'payment-method':
      return axios.create({
        baseURL: baseUrl,
      });
    case 'discount-handler':
      return axios.create({
        baseURL: baseUrl,
      });
    case 'confirm-appointment-handler':
      return axios.create({
        baseURL: baseUrl,
      });
    default:
      return axios.create({
        baseURL: baseUrl,
      });
  }
};
