import { ThemeProvider } from 'styled-components';

import { useWidgetConfig } from 'hooks/useWidgetStyles';

export const WidgetThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { theme } = useWidgetConfig();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
